import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Ultimul dintre cursurile traduse în limba română și care este destinat aproape în exclusivitate pastorilor și liderilor de biserici este o resursă importantă pentru o slujire eficientă.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Dacă vrei să ai o mai mare claritate și focalizare în lucrarea unică la care Dumnezeu te-a chemat; dacă dorești să-ți dezvolți un plan strategic bazat pe scopul biblic, valorile tale de viață și slujire dar și pe viziunea ce Domnul ți-o dă pentru biserică; dacă te confrunți cu nevoia de mai mulți slujitori echipați și focalizați pe lucrarea specifică; dacă vrei să înveți ce este și să practici mentorarea; dacă vrei să slujești crezând și promovând conducerea bazate pe caracter; dacă vrei să conduci bisericile în care slujești spre un mare seceriș… atunci ai nevoie să te așezi împreună cu un grup de colegi și să parcurgi împreună cel mai practic curs de pastorație, care prin cele 8 module o să te ajute să faci cu pasiune o lucrare eficientă pentru Hristos.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Acum (2008) lucrăm cu frații pastori recomandați de conducerea comunităților regionale, cu speranța că acești oameni din autoritate vor folosi aceste materiale în slujirea lor.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Esența conducerii biblice este capacitatea de a-i influența pe oamenii lui Dumnezeu către scopurile lui Dumnezeu. Împlinirea scopurilor lui Dumnezeu este esența adevăratei conduceri. Deosebirea dintre conducerea creștină și organizarea seculară este capacitatea conducerii creștine de a-i influența pe bărbați și femei să trăiască pentru scopurile cele mai înalte ale lui Dumnezeu.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    